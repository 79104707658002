import React from 'react'
import { Layout, SEO } from "@startuponsale/gatsby-shared-ui";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ShareButtons from '../components/share-buttons'
import Article from '../components/Articles/Article';
import website from "../../config/website";

const PostTemplate = ({ data, location }) => {
  const {
    mdx: {
      frontmatter: { title, image, date, keywords, description},
      fields: { readingTime: { text, minutes }},
      body,
      relatedPosts
    },
  } = data;

  console.log(description)

  return (
    <Layout customSEO blog>
      <SEO
        title={`${title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={description}
        publishedDate={date}
        uniqueKeywords={keywords}
        article
      />
      <div className="w-full services bg-white">
        <div className="max-w-6xl mx-auto px-4 pt-8 pb-20 md:pb-24">
          {/* post info*/}
          <article>
            <h1 className="text-3xl font-bold text-dpurple nexaTextBold pb-4">
              {title}
            </h1>
            <div className="pb-6 text-gray-500" >{date.charAt(0).toUpperCase() + date.slice(1)} - {Math.ceil(minutes)} minutos de lectura
              <ShareButtons url={website.url + location.pathname} title={title} description={description}/>
            </div>
            <div className="container">
              <GatsbyImage
                image={getImage(image)}
                alt={title}
                className="float-right rounded-2xl md:max-w-md object-cover stuugleImg ml-2"
              />
              <div className="left">
                <MDXRenderer>{body}</MDXRenderer>
              </div>
            </div>
          </article>
        </div>
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-dpurple nexaTextBold pb-4 w-full">
              También te puede interesar
          </h3>
          <div className=" container max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-7 pb-20"> 
            
            {relatedPosts.map((post) => {
              return <Article key={post.id} {...post} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};


export const query = graphql`
query GetSinglePost($slug: String) {
  mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      date(formatString: "MMMM, YYYY", locale: "es")
      category
      slug
      title
      readTime
      keywords
      description
      image {
        childImageSharp {
          gatsbyImageData (
            width: 448
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    fields {
      readingTime {
        text
        minutes
      }
    }
    body
    relatedPosts(limit: 3) {
      id
      excerpt
      frontmatter {
        title
          author
          category
          readTime
          slug
          date(formatString: "MMMM, Do YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
      }
    }
  }
}
`


export default PostTemplate
