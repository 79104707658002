module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Startup On Sale - Recursos para emprendedores", // Navigation and Site Title
  titleAlt: "Startup On Sale - Recursos para emprendedores", // Title for JSONLD
  description:
    "Startup On Sale es la comunidad donde podrás vender tu proyecto o startup fácilmente, dónde podrás conectar con gente interesada en tu proyecto gratuitamente. Aquí están los recursos y artículos que necesitaras para vender o comprar tu proyecto.",
  headline: "Vende y compra proyectos o startups fácilmente en StartupOnSale.", // Headline for schema.org JSONLD
  url: "https://recursos.startuponsale.com", // Domain of your site. No trailing slash!
  siteLanguage: "es", // Language Tag on <html> element
  logo: "/static/platform.png", // Used for SEO
  ogLanguage: "es_ES", // Facebook Language
  keywords: "startups, proyectos, vender, comprar, adquirir, side-projects",

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "StartupOnSale", // shortname for manifest. MUST be shorter than 12 characters
  author: "StartupOnSale", // Author for schemaORGJSONLD
  themeColor: "#3D63AE",
  backgroundColor: "#EBEDF2",

  twitter: "@startuponsale", // Twitter Username
  facebook: "startup-on-sale", // Facebook Site Name
  googleAnalyticsID: "G-60SCNYMQNH",
};
